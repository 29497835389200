import React,{useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import {useHistory} from 'react-router-dom'

const ProtectedRoute = ({ component: Component,isAuthenticated, ...rest }) => {
  

    return (
        <>
            <Route
                {...rest}
                component={props => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)}
            />
        </>
    );
};

ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated
    };
};
export default connect(mapStateToProps)(ProtectedRoute);
