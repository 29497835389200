import CONSTANTS from "../globals/constant";
import { blobToBase64 } from "../helpers/blobToBase64";
import fireSpark from "../helpers/spark";
import {store} from '../redux/store'
import jwt_decode from "jwt-decode";
import * as Types from "../redux/actions/types/authTypes";

const getResponse = async (response) => {
	const contentType = response.headers.get("content-type");

	// for json response
	if (contentType === "application/json") {
		const json = await response.json();
		return { response, json };
	}

	// for image response
	if (contentType === "image/jpeg") {
		response
			.blob()
			.then(blobToBase64)
			.then((base64image) => {
				return { response, base64image };
			});
	}
};

const pubRequest = async (url, method, body, signal) => {
	try {
		if (window.navigator.onLine) {
			const mainCall = async () => {
				return await fetch(`${CONSTANTS.SERVER_URL}${url}`, {
					method: method,
					credentials: "include",
					body: method !== "GET" ? JSON.stringify(body) : null,
					signal: signal,
					headers: {
						"Content-Type": "application/json",
						
					}
				});
			};

			var response = await getResponse(await mainCall());
			return response;
		} else {
			fireSpark("error", "you are offline!!");
		}
	} catch (err) {
		throw err;
	}
};


const checkTokenExpiration = async(accessToken,refreshToken,signal)=>{
	if(jwt_decode(accessToken).exp* 1000 <Date.now()){

		console.log("Is expired....")
		let dataToSend= {
         refresh:refreshToken,
         access:accessToken
    	}

		await fetch(`${CONSTANTS.SERVER_URL}/api/users/login/refresh`, {
		        method: "POST",
		        credentials: "include",
		        body:  JSON.stringify(dataToSend) ,
				signal:signal,
		        headers: {
		            // Authorization: `Bearer ${dataToSend.access}`,
		            "Content-Type": "application/json"
		        }
		    }) .then(response => response.json())
		    .then(data => {
				console.log(data)
				if(data.status){
					store.dispatch({ type: Types.REFRESH_TOKEN, payload: {access:data.access,refresh:data.refresh} })
				}
				
			})
			.catch(err=>{
				
				console.log(err)
			});
		
		return true;
	}
	else{
		console.log("Not expired...")
		return false;
	}
}


const privateRequest = async (url, method, body, signal) => {
	try {
		if (window.navigator.onLine) {

			let accessToken = store.getState().Auth.access;
			let refreshToken = store.getState().Auth.refresh;

			 checkTokenExpiration(accessToken,refreshToken,signal);

			const mainCall = async () => {
				return await fetch(`${CONSTANTS.SERVER_URL}${url}`, {
					method: method,
					credentials: "include",
					body: method !== "GET" ? JSON.stringify(body) : null,
					signal: signal,
					headers: {
						Authorization: `${store.getState().Auth.access}`,
						"Content-Type": "application/json"
					}
				});
			};

			var response = await getResponse(await mainCall());
			return response;
		} else {
			fireSpark("error", "you are offline!!");
		}
	} catch (err) {
		throw err;
	}
};

export { pubRequest,privateRequest };
