import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlogs } from "../api/public/blog";

export const getFeatured = createAsyncThunk("blogs/get/featured", async () => {
    try {
        return await getBlogs();
    } catch (err) {
        return err;
    }
});

const initialState = {
    success: false,
    loading: false,
    blogList: [],
    error: ""
};

export const blogSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        reset: (state) => {
            state = { ...initialState };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeatured.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFeatured.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
                state.blogList = payload?.json?.data;
            })
            .addCase(getFeatured.rejected, (state, { payload }) => {
                state.loading = false;
                state.success = false;
                state.error = payload;
            });
    }
});

export const { reset } = blogSlice.actions;
export const featuredBlogsSelector = (state) => state.blogStore.blogList;

export default blogSlice.reducer;
