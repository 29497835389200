import React from "react";

const chatboxRoutes = ["/", "/feature", "/pricing", "/documentation"];

const Chatbox = (props) => {
    const path = props.path;
    if (!chatboxRoutes.includes(path)) {
        return null;
    }
    return (
        <div className="chatbox">
            <p>Chat with us</p>
        </div>
    );
};

export default Chatbox;
