import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./authReducer";
import layoutReducer from "./layoutReducer";
import payment from "../payment";
import blog from "../blogs";
import faq from "../faq";
import dashLayoutReducer from "./dashLayoutReducer";
import paymentDetails from "./paymentReducer";

const rootReducer = combineReducers({
    main: mainReducer,
    Auth: authReducer,
    layout: layoutReducer,
    paymentStore: payment,
    paymentDetails: paymentDetails,
    blogStore: blog,
    faqStore: faq,
    dashLayout: dashLayoutReducer
});

const persistConfig = {
    key: "BMroot",
    storage: storage,
    whitelist: ["Auth"]
};

const pReducer = persistReducer(persistConfig, rootReducer);

export default pReducer;
