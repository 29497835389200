import React from "react";
import { NavLink } from "react-router-dom";

const navOptions = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "Feature",
        path: "/feature"
    },
    {
        name: "Pricing",
        path: "/pricing"
    },
    {
        name: "Documentation",
        path: "/documentation"
    }
];

function NavItems({ account }) {
    if (account) {
        return navOptions.slice(1).map((nav, index) => (
            <NavLink key={index} activeClassName="active" className="" exact to={nav.path}>
                <span>{nav.name}</span>
            </NavLink>
        ));
    }

    return (
        <ul>
            {navOptions.map((nav, index) => {
                return (
                    <li key={index}>
                        <NavLink activeClassName="active" className="" exact to={nav.path}>
                            {nav.name}
                        </NavLink>
                    </li>
                );
            })}
        </ul>
    );
}

export default NavItems;
