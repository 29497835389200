import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { composeWithDevTools } from "redux-devtools-extension";
const middlewares = [reduxThunk];

const store = createStore(rootReducer, undefined, composeWithDevTools(applyMiddleware(...middlewares)));

const persistor = persistStore(store, { storage: storage });

export { store, persistor };
