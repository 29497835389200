import React from "react";

import {BsFacebook} from 'react-icons/bs'
import BizmandalaLogo from "../../../components/reusable/others/Logo";
const footerRoutes = ["/", "/feature", "/pricing", "/documentation"];

const Footer = (props) => {
    const path = props.path;
    if (!footerRoutes.includes(path)) {

        if(path==='/payment'){
            return (
                <div className="footer ">
                    <div className="payment-footer ">
                    <div className="footer__menu">
                       <ul>
                           <li>
                                Quick Link
                           </li>
                           <li>
                                Features
                           </li>
                           <li>
                                Menu Title
                           </li>
                           <li>
                                Others
                           </li>
                       </ul>
                    </div>
                    <div className="footer__copy">
                        Bizmandala Copyright protected, 2020
                    </div>
                    </div>
                    
                </div>
            )
        }

        return (
            <div className="footer">
                <div className="footer__top">
                    <div className="biz">
                        <BizmandalaLogo />
                    </div>
                    <div className="threedots">
                        <ThreeDots />
                    </div>
                </div>

                <div>
                    <p className="footer__copyright">&#169; Copyright Protected 2021</p>
                </div>
            </div>
        );
    }

    return (
        <div className="footer">
            <div className="footer__top">
                <div className="biz">
                    <BizmandalaLogo />
                </div>

                {["Quick Links", "Features", "Menu Title"].map((title, i) => (
                    <div key={i}>
                        <p className="footer--title">{title}</p>
                        {["About Us", "Contact", "Menu Items", "About Us", "Contact"].map((link, i) => (
                            <li key={i}>
                                <a href="#home">{link}</a>
                            </li>
                        ))}
                    </div>
                ))}

                <div>
                    <p className="footer--title">Others</p>
                    {["Privacy Policy", "Terms & Conditions", "Menu Items", "About Us", "About"].map((d, i) => (
                        <li key={i}>
                            <a href="#home">{d}</a>
                        </li>
                    ))}
                </div>
                <div className="threedots">
                    <div className="threedots__header">
                        Connect With US
                    </div>
                    <ThreeDots />
                </div>
            </div>

            <div>
                <p className="footer__copyright">&#169; Copyright Protected 2021</p>
            </div>
        </div>
    );
};

export default Footer;

const ThreeDots = () => {
    return (
        <div className="threedots__wrapper">

            <div className="threedots__dot">

                <BsFacebook/>

            </div>
            <div className="threedots__dot">

                <BsFacebook/>

            </div>
            <div className="threedots__dot">

                <BsFacebook/>

            </div>

        </div>
    );
};
