import * as Types from "./types";

export const changeDashMenuStatus = status => {
    return dispatch => {
        dispatch({ type: Types.SET_DASHBOARD_MENU_ON, payload: status });
    };
};

export const setActiveSideMenu = menu => {
    return dispatch => {
        dispatch({ type: Types.SET_ACTIVE_SIDE_MENU, payload: menu });
    };
};

export const setDocumentationDashboard = status=>{
    return dispatch=>{
        dispatch({type:Types.IS_USER_DOCUMENTATION_PAGE,payload:status})
    }
}
