import React from 'react'
import {ReactComponent as TicketNotf} from '../../assets/icons/human-resources/svg/ticket-notf.svg';
import {ReactComponent as PaymentNotf} from '../../assets/icons/human-resources/svg/payment-notf.svg';
import {ReactComponent as LoginNotf} from '../../assets/icons/human-resources/svg/login-notf.svg';

function IndividualNotification({data}) {
    return (
        <div className={`notf ${data.status==='opened'?'notf--seen':''}`}>

            <div className="notf__icon">

                {
                    data.type==='ticket' && <TicketNotf/>
                }
                {
                    data.type==='payment' && <PaymentNotf/>
                }
                {
                    data.type==='login' && <LoginNotf/>
                }

            </div>
            <div className="notf__details">

                <div className="notf__details__title">

                    {data.title}

                </div>

                <div className="notf__details__time">
                    
                    {data.time}

                </div>

            </div>
            
        </div>
    )
}

export default IndividualNotification
