import React from "react";
const Guides = React.lazy(() => import("../../pages/Dashboard/Guides"));

const DashboardMain = React.lazy(() => import("../../pages/Dashboard/DashboardMain"));
const Accounts = React.lazy(() => import("../../pages/Dashboard/Accounts"));
const AddAccount =  React.lazy(() => import("../../pages/Dashboard/Accounts/AddAccount"));
const IndividualAccount = React.lazy(() => import("../../pages/Dashboard/Accounts/IndividualAccount"));
const UserInvoice = React.lazy(() => import("../../pages/Dashboard/Accounts/Invoice"));

const Products = React.lazy(() => import("../../pages/Dashboard/Products"));
const Analytics = React.lazy(() => import("../../pages/Dashboard/Analytics"));

// blogs
const Blogs = React.lazy(() => import("../../pages/Dashboard/Blogs"));
const Scheduled = React.lazy(() => import("../../pages/Dashboard/Blogs/Scheduled"));
const Published = React.lazy(() => import("../../pages/Dashboard/Blogs/Published"));

// tickets
const Tickets = React.lazy(() => import("../../pages/Dashboard/Tickets"));
const TicketDetails = React.lazy(() => import("../../pages/Dashboard/Tickets/TicketDetails"));
const USerTicketDetails = React.lazy(() => import("../../pages/Dashboard/Tickets/TicketDetails"));

// settings
const Settings = React.lazy(() => import("../../pages/ClientView/Settings"));
const GlobalSettings = React.lazy(() => import("../../pages/Dashboard/Settings/GlobalSettings"));
//Client side
//const ClientDashHome = React.lazy(() => import("../../pages/ClientView/ClientDashHome"));
const Payment = React.lazy(() => import("../../pages/Public/Payment/index"));


const EmbeddedLinks = React.lazy(() => import("../../pages/Dashboard/EmbeddedLinks"));
const IndividualEmbeddedLink = React.lazy(() => import("../../pages/Dashboard/ViewEmbeddedLink"));
const Faqs = React.lazy(() => import("../../pages/Dashboard/Faqs"));
const IndividualFaqPage = React.lazy(() => import("../../pages/Dashboard/ViewFaqs"));
const FeaturedClients = React.lazy(() => import("../../pages/Dashboard/FeaturedClients"));
const IndividualFeaturedClient = React.lazy(() => import("../../pages/Dashboard/ViewFeaturedClient"));
const HighlightedProducts = React.lazy(() => import("../../pages/Dashboard/HighlightedProducts"));

const Companies = React.lazy(() => import("../../pages/ClientView/Companies"));
const IndividualCompany = React.lazy(() => import("../../pages/ClientView/IndividualCompany"));
const Subscription = React.lazy(() => import("../../pages/ClientView/Subscription"));
const AddSubscription = React.lazy(() => import("../../pages/ClientView/AddSubscription"));

const DashBoard_Documentation = React.lazy(() => import("../../pages/ClientView/Documentation"));
const DashBoard_Blog = React.lazy(() => import("../../pages/ClientView/Blogs"));
const DashBoard_Individual_Blog = React.lazy(() => import("../../pages/ClientView/IndividualBlog"));


//form response
const DisplayFormResponse = React.lazy(() => import("../../pages/Dashboard/FormResponse/DisplayResponse"));
const IndividualFormResponse = React.lazy(() => import("../../pages/Dashboard/FormResponse/IndividualFormResponse"));

// page not found
const PageNotFound = React.lazy(() => import("../../pages/NotFound"));

const staffDashboardRoutes = [
    {
        path: "/dashboard",
        exact: true,
        name: "Dashboard",
        component: DashboardMain
    },
    {
        path: "/dashboard/subscriptions",
        exact: true,
        name: "Subscriptions",
        component: Accounts
    },
    {
        path: "/dashboard/subscriptions/add-account",
        exact: true,
        name: "Add Account",
        component: AddAccount
    },
    {
        path: "/dashboard/subscriptions/user/invoice/:id",
        exact: true,
        name: "Invoice",
        component: UserInvoice
    },
    {
        path: "/dashboard/subscriptions/user/:id",
        exact: true,
        name: "Individual Subscription",
        component: IndividualAccount
    },
   

    {
        path: "/dashboard/products",
        exact: true,
        name: "Products",
        component: Products
    },

    {
        path: "/dashboard/analytics",
        exact: true,
        name: "Analytics",
        component: Analytics
    },
    {
        path: "/dashboard/blogs",
        exact: true,
        name: "Blogs",
        component: Blogs
    },
    {
        path: "/dashboard/scheduled",
        exact: true,
        name: "Scheduled",
        component: Scheduled
    },
    {
        path: "/dashboard/published",
        exact: true,
        name: "Published",
        component: Published
    },
    {
        path: "/dashboard/tickets",
        exact: true,
        name: "Tickets",
        component: Tickets
    },
    {
        path: "/dashboard/tickets/ticket/:id",
        exact: true,
        name: "Tickets",
        component: TicketDetails
    },
    {
        path: "/dashboard/form-response",
        exact: true,
        name: "Form response",
        component: DisplayFormResponse
    },
    {
        path: "/dashboard/form-response/form/:id",
        exact: true,
        name: "Individual Form Response",
        component: IndividualFormResponse
    },
    {
        path: "/dashboard/settings",
        exact: true,
        name: "Settings",
        component: GlobalSettings
    },
    {
        path: "/dashboard/companies",
        exact: true,
        name: "Companies",
        component: Companies
    },

    {
        path: "/dashboard/companies/company",
        exact: true,
        name: "Companies",
        component: IndividualCompany
    },
    {
        path: "/dashboard/subscriptions",
        exact: true,
        name: "Subscription",
        component: Subscription
    },
    {
        path: "/dashboard/documentation",
        exact: true,
        name: "Documentation",
        component: DashBoard_Documentation
    },
    {
        path: "/dashboard/client_blogs",
        exact: true,
        name: "DashBoard_Blog",
        component: DashBoard_Blog
    },
    {
        path: "/dashboard/client_blogs/blog",
        exact: true,
        name: "DashBoard_Blog",
        component: DashBoard_Individual_Blog
    },
    {
        path: "/dashboard/guides",
        exact: true,
        name: "Style Guides",
        component: Guides
    },
    {
        path: "/dashboard/highlighted-products",
        exact: true,
        name: "Highlighted product",
        component: HighlightedProducts
    },
    {
        path:  "/dashboard/featured-clients",
        exact: true,
        name: "Featured client",
        component: FeaturedClients
    },
    {
        path:  "/dashboard/featured-clients/client/:id",
        exact: true,
        name: "Featured client",
        component: IndividualFeaturedClient
    },
    {
        path:  "/dashboard/embeded-links",
        exact: true,
        name: "Embeded links",
        component: EmbeddedLinks
    },
    {
        path:  "/dashboard/embeded-links/link/:id",
        exact: true,
        name: "Embeded links",
        component: IndividualEmbeddedLink
    },
    
    {
        path: "/dashboard/faqs",
        exact: true,
        name: "Faqs",
        component: Faqs
    },
    {
        path: "/dashboard/faqs/faq/:id",
        exact: true,
        name: "Faqs",
        component: IndividualFaqPage
    },
    {
        path: "/dashboard/404pagenotfound",
        exact: true,
        name: "404NotFound",
        component: PageNotFound
    }
];

const userDashboardRoutes = [
    // {
    //     path: "/user/dashboard",
    //     exact: true,
    //     name: "Dashboard",
    //     component: ClientDashHome
    // },
    {
        path: "/user/dashboard/blogs",
        exact: true,
        name: "Blogs",
        component: Blogs
    },
    {
        path: "/user/dashboard/scheduled",
        exact: true,
        name: "Scheduled",
        component: Scheduled
    },
    {
        path: "/user/dashboard/published",
        exact: true,
        name: "Published",
        component: Published
    },
    {
        path: "/user/dashboard/tickets",
        exact: true,
        name: "Tickets",
        component: Tickets
    },
    {
        path: "/user/dashboard/tickets/ticket",
        exact: true,
        name: "Tickets",
        component: USerTicketDetails
    },
    {
        path: "/user/dashboard/settings",
        exact: true,
        name: "Settings",
        component: Settings
    },
    {
        path: "/user/dashboard/erp",
        exact: true,
        name: "Erp",
        component: Companies
    },

    {
        path: "/user/dashboard/companies/company",
        exact: true,
        name: "Companies",
        component: IndividualCompany
    },
    {
        path: "/user/dashboard/subscriptions",
        exact: true,
        name: "Subscription",
        component: Subscription
    },
    {
        path: "/user/dashboard/payment",
        exact: true,
        name: "Payment",
        component: Payment
    },
    {
        path: "/user/dashboard/subscriptions/add",
        exact: true,
        name: "Add Subscription",
        component: AddSubscription
    },
    {
        path: "/user/dashboard/documentation",
        exact: true,
        name: "Documentation",
        component: DashBoard_Documentation
    },
    {
        path: "/user/dashboard/guides",
        exact: true,
        name: "Style Guides",
        component: Guides
    },
   
    {
        path: "/user/dashboard/404pagenotfound",
        exact: true,
        name: "404NotFound",
        component: PageNotFound
    }
];

export { staffDashboardRoutes, userDashboardRoutes };
