// all api calls for blog page

import { pubRequest } from "../wrapper";

export const getBlogs = async (query = "limit=6", signal) => {
    try {
        return await pubRequest("api/site/blogs/" + (query ? "?" + query : ""), "GET", signal);
    } catch (err) {
        throw err;
    }
};

export const getOneBlog = async (id) => {
    try {
        return await pubRequest("api/site/blogs/" + id, "GET");
    } catch (err) {
        throw err;
    }
};

export const getComments = async (id) => {
    try {
        return await pubRequest("api/site/blogs/" + id + "/comments/", "GET");
    } catch (err) {
        throw err;
    }
};

export const postComment = async (id, comment) => {
    try {
        return await pubRequest("api/site/blogs/" + id + "/comments/", "POST", comment);
    } catch (err) {
        throw err;
    }
};
