import React, { Suspense, useEffect } from "react";
import "../assets/styles/main.scss";
import { Route, withRouter } from "react-router-dom";
import { setStaticMenu, setResponsiveIndicators, changeDashMenuStatus } from "../redux/actions";
import { connect } from "react-redux";
import Wrapper from "../hoc/Wrapper";
import pubRoutes from "../routes/pubRoutes";
import PublicRoute from "../hoc/PublicRoute";
import ScrollToTop from "../hoc/ScrollToTop";
import Main from "./main/Main";
import { Switch } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import ProtectedRoute from "../hoc/ProtectedRoute";
import { staffDashboardRoutes, userDashboardRoutes } from "../routes/dashboard/dashboardRoutes";
import PropTypes from "prop-types";
import TopLoader from "../components/reusable/Loaders/TopLoader";
import {useSelector,useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom'
import NotFoundPage from '../pages/NotFound'

function App(props) {

    // const AuthState = useSelector(state => state.Auth)
    const LayoutState =  useSelector(state => state.layout.responsiveIndicators);
    const isAuthenticated = props.isAuthenticated;

    const dispatch = useDispatch();
    const location= useLocation();

    useEffect(() => {
        // on load
        let { standardBreakpoints, setResponsiveIndicators } = props;
        if (window.innerWidth <= standardBreakpoints.xsSmallScreen) {
            setResponsiveIndicators({
                isXsSmallScreen: true,
                isSmallScreen: false,
                isMediumScreen: false,
                isLargeScreen: false,
                isXlLargeScreen: false
            });
        } else if (
            window.innerWidth <= standardBreakpoints.smallScreen &&
            window.innerWidth > standardBreakpoints.xsSmallScreen
        ) {
            setResponsiveIndicators({
                isXsSmallScreen: false,
                isSmallScreen: true,
                isMediumScreen: false,
                isLargeScreen: false,
                isXlLargeScreen: false
            });
        } else if (
            window.innerWidth <= standardBreakpoints.mediumScreen &&
            window.innerWidth > standardBreakpoints.smallScreen
        ) {
            setResponsiveIndicators({
                isXsSmallScreen: false,
                isSmallScreen: false,
                isMediumScreen: true,
                isLargeScreen: false,
                isXlLargeScreen: false
            });
        } else if (
            window.innerWidth <= standardBreakpoints.largeScreen &&
            window.innerWidth > standardBreakpoints.mediumScreen
        ) {
            setResponsiveIndicators({
                isXsSmallScreen: false,
                isSmallScreen: false,
                isMediumScreen: false,
                isLargeScreen: true,
                isXlLargeScreen: false
            });
        } else if (window.innerWidth > standardBreakpoints.largeScreen) {
            setResponsiveIndicators({
                isXsSmallScreen: false,
                isSmallScreen: false,
                isMediumScreen: false,
                isLargeScreen: false,
                isXlLargeScreen: true
            });
        }

        // on screen resize
        window.addEventListener("resize", resize());
        function resize() {
            if (window.innerWidth <= standardBreakpoints.xsSmallScreen) {
                setResponsiveIndicators({
                    isXsSmallScreen: true,
                    isSmallScreen: false,
                    isMediumScreen: false,
                    isLargeScreen: false,
                    isXlLargeScreen: false
                });
            } else if (
                window.innerWidth <= standardBreakpoints.smallScreen &&
                window.innerWidth > standardBreakpoints.xsSmallScreen
            ) {
                setResponsiveIndicators({
                    isXsSmallScreen: false,
                    isSmallScreen: true,
                    isMediumScreen: false,
                    isLargeScreen: false,
                    isXlLargeScreen: false
                });
            } else if (
                window.innerWidth <= standardBreakpoints.mediumScreen &&
                window.innerWidth > standardBreakpoints.smallScreen
            ) {
                setResponsiveIndicators({
                    isXsSmallScreen: false,
                    isSmallScreen: false,
                    isMediumScreen: true,
                    isLargeScreen: false,
                    isXlLargeScreen: false
                });
            } else if (
                window.innerWidth <= standardBreakpoints.largeScreen &&
                window.innerWidth > standardBreakpoints.mediumScreen
            ) {
                setResponsiveIndicators({
                    isXsSmallScreen: false,
                    isSmallScreen: false,
                    isMediumScreen: false,
                    isLargeScreen: true,
                    isXlLargeScreen: false
                });
            } else if (window.innerWidth > standardBreakpoints.largeScreen) {
                setResponsiveIndicators({
                    isXsSmallScreen: false,
                    isSmallScreen: false,
                    isMediumScreen: false,
                    isLargeScreen: false,
                    isXlLargeScreen: true
                });
            }
        }
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);
    // eslint-disable-line react-hooks/exhaustive-deps

    
    // useEffect(() => {
    //     if (!isAuthenticated) {
    //         props.history.push("/");
    //     }
    // }, [isAuthenticated]);

    const publicRoutes = pubRoutes.map((rou, ind) => {
        return (
            <PublicRoute
                key={ind}
                path={rou.path}
                exact={rou.exact}
                component={props => <rou.component {...props} />}
            />
        );
    });


    
    useEffect(() => {
        if( isAuthenticated && (LayoutState.isXsSmallScreen || LayoutState.isSmallScreen ) ){
                dispatch(changeDashMenuStatus(false))
        }
        
    }, [location.pathname,isAuthenticated])

    let dashboardRoutes =
       props.isAuthenticated?
            props.role === "staff" ? staffDashboardRoutes : props.role === "user" ? userDashboardRoutes : []
            :
            [];

    //let dashboardRoutes =[...staffDashboardRoutes,...userDashboardRoutes]
      
    const protectedRoutes = dashboardRoutes.map((rou, ind) => {
        
        return (
          <ProtectedRoute
                key={ind}
                path={rou.path}
                exact={rou.exact}
                isAuthenticated={props.isAuthenticated}
                component={ props => <rou.component {...props} />}
            />
        );
    });

    return (
        <Wrapper>
            <ScrollToTop>
                {/* public flow */}
                <Main isAuthenticated={props.isAuthenticated}>
                    <Suspense fallback={<TopLoader />}>
                        <Switch>{publicRoutes}</Switch>
                    </Suspense>
                </Main>
                {/* dashboard flow */}
                <Dashboard isAuthenticated={props.isAuthenticated}>
                    <Suspense fallback={<TopLoader />}>
                        <Switch>
                            {protectedRoutes}
                        </Switch>
                    </Suspense>
                </Dashboard>
                
                       
                   
            </ScrollToTop>
        </Wrapper>
    );
}

App.propTypes = {
    role: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    staticMenuOn: PropTypes.bool.isRequired,
    responsiveIndicators: PropTypes.object.isRequired,
    standardBreakpoints: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        role: state.Auth.role,
        isAuthenticated: state.Auth.isAuthenticated,
        staticMenuOn: state.main.staticMenuOn,
        responsiveIndicators: state.layout.responsiveIndicators,
        standardBreakpoints: state.layout.standardBreakpoints
    };
};

export default connect(
    mapStateToProps,
    {
        setStaticMenu,
        setResponsiveIndicators
    }
)(withRouter(App));
