import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                component={props =>
                    !rest.isAuthenticated ? (
                        <Component {...props} />
                    ) : 
                    ( rest.role === "staff" ? 
                    (
                        <Redirect to="/dashboard" />
                    ) : (rest.role === "user" && rest.path!=="/payment" ? (
                        <Redirect to="/user/dashboard" />
                    ) :
                    null))
                }
            />
        </>
    );
};

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    role: PropTypes.string
};
const mapStateToProps = state => {
    return {
        role: state.Auth.role,
        isAuthenticated: state.Auth.isAuthenticated
    };
};

export default connect(mapStateToProps)(PublicRoute);
