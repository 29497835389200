import React from "react";

const Ham = (props) => {
	return (
		<div onClick={props.onClick} className="ham">
			<div>
				MENU
			</div>
			<div>
			<svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8047 12.8984H0.804688V10.8984H18.8047V12.8984ZM18.8047 7.89844H0.804688V5.89844H18.8047V7.89844ZM18.8047 2.89844H0.804688V0.898438H18.8047V2.89844Z" fill="black"/>
</svg>

			</div>
			

		</div>
	);
};

export default Ham;
