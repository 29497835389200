import React,{useState,useEffect} from "react";
import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import { connect } from "react-redux";
import {useSelector,useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import { setDocumentationDashboard } from "redux/actions";

function Dashboard(props) {

    let dashboardContentClass = ["dashboard-content", "dash-content-open", "datta-scroll"];
    if (!props.dashMenuOn) {
        dashboardContentClass = ["dashboard-content", "dash-content-close", "datta-scroll"];
    }

    const location = useLocation();
    const dispatch = useDispatch();
    const isUserDocumentaionPage =  useSelector(state => state.dashLayout.isUserDocumentaionPage);

    useEffect(() => {
        if(isUserDocumentaionPage){
            dispatch(setDocumentationDashboard(false))
        }
    }, [location.pathname])

   

    return (
        <div className={`dashboard-layout ${!props.isAuthenticated && "display-none"}`}>
            {props.isAuthenticated && (
                <>
                    {/* top-bar */}
                    <Topbar isUserDocumentaionPage={isUserDocumentaionPage}/>
                    {/* side-bar */}
                    {
                        isUserDocumentaionPage?
                        <Sidebar  isUserDocumentaionPage={isUserDocumentaionPage}/>
                        :
                        <Sidebar />

                    }
                </>
            )}
            {/* dashboard body */}
            <div 
            className={dashboardContentClass.join(" ")}>
                {props.children}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        dashMenuOn: state.dashLayout.dashMenuOn
    };
};

export default connect(mapStateToProps)(Dashboard);
