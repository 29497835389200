import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeDashMenuStatus, setActiveSideMenu } from "../../../redux/actions";
import Logo from "./Logo";
import { menuItems, bottomMenuItems, userMenuItems, userBottomMenuItems } from "../../../routes/dashboard/menuItems";
import PropTypes from "prop-types";
import MenuItem from "./MenuItem";
import MenuDivider from "../../../components/reusable/Dividers/MenuDivider";
import MenuSubItem from "./MenuSubItem";
import MenuBottomItems from "./MenuBottomItems";
import IconMenuSubItem from "./IconMenuSubItem";
import isEmpty from "../../../utils/isEmpty";
import {useDispatch} from 'react-redux'

function Sidebar(props) {
    let sidebarClasses = ["dashboard-sidebar", "menu-open"];
    if(props.isUserDocumentaionPage){
        sidebarClasses = ["dashboard-sidebar","dashboard-sidebar--documentation", "menu-open"];
    }
    if (!props.dashMenuOn) {
        sidebarClasses = props.isUserDocumentaionPage?["dashboard-sidebar","dashboard-sidebar--documentation", "menu-close"]:["dashboard-sidebar", "menu-close"];
    }
    const dispatch = useDispatch();

    let sideMenuContent;
    let menuItemsList =
        props.role && props.role === "staff" ? menuItems : props.role && props.role === "user" ? userMenuItems : [];

    if (props.dashMenuOn) {
        sideMenuContent = (
            <ul className={`dashboard-sidebar-menu ${props.role === "user" && "user-top"}`}>
                {menuItemsList.map((item, index) => {
                    return (
                        <>
                            <MenuItem
                                activeMenu={props.activeSideMenu}
                                onItemClick={props.setActiveSideMenu}
                                key={index}
                                item={item}
                            />
                            {props.activeSideMenu === item.id &&
                                (item.children && !isEmpty(item.children)
                                    ? item.children.map((subItem, subIndex) => {
                                          return (
                                              <MenuSubItem
                                                  onItemClick={props.setActiveSideMenu}
                                                  activeMenu={props.activeSideMenu}
                                                  item={subItem}
                                                  subIndex={subIndex}
                                              />
                                          );
                                      })
                                    : null)}
                        </>
                    );
                })}
            </ul>
        );
    } else {
        sideMenuContent = (
            <ul className={`dashboard-sidebar-menu mini ${props.role === "user" && "user-top"}`}>
                {menuItemsList.map((item, index) => {
                    return (
                        <>
                            <MenuItem
                                activeMenu={props.activeSideMenu}
                                onItemClick={props.setActiveSideMenu}
                                key={index}
                                item={item}
                            />
                            {props.activeSideMenu === item.id &&
                                (item.children && !isEmpty(item.children)
                                    ? item.children.map((subItem, subIndex) => {
                                          return (
                                              <IconMenuSubItem
                                                  onItemClick={props.setActiveSideMenu}
                                                  activeMenu={props.activeSideMenu}
                                                  item={subItem}
                                                  subIndex={subIndex}
                                              />
                                          );
                                      })
                                    : null)}
                        </>
                    );
                })}
            </ul>
        );
    }

    let bottomItemsList =
        props.role && props.role === "staff"
            ? bottomMenuItems
            : props.role && props.role === "user"
            ? userBottomMenuItems
            : [];
    const sidebarBottom = (
        <ul
            className={`dashboard-sidebar-bottom ${props.dashMenuOn ? "" : "mini"} ${props.role === "user" &&
                "user-bottom"}`}
        >
            {bottomItemsList.map((data, ind) => {
                return (
                    <>
                        <MenuBottomItems
                            activeMenu={props.activeSideMenu}
                            onItemClick={props.setActiveSideMenu}
                            item={data}
                            indexx={ind}
                        />
                    </>
                );
            })}
        </ul>
    );

    const closeSidebar = ()=>{
        dispatch(changeDashMenuStatus(false))
    }

    return (
        <>
        <div className="dashboard-sidebar-wrapper">
            <div className={sidebarClasses.join(" ")}>
                
                <div className={`dashboard-sidebar-top ${!props.dashMenuOn && "icon-mode"}`}>
                    <div className="dashboard-sidebar-top-logo">
                        <Logo
                            onClick={() => {
                                props.history.push("/dashboard");
                            }}
                        />
                    </div>
                    <i
                        onClick={() => {
                            props.changeDashMenuStatus(false);
                        }}
                        className="icon feather icon-x"
                    />
                </div>
                <MenuDivider />
                {sideMenuContent}
                <MenuDivider />
                {sidebarBottom}
                

{
    props.isUserDocumentaionPage &&
            <div className={`dashboard-sidebar__documentation `}>
                <div className="dashboard-sidebar__documentation__close-btn" onClick={closeSidebar}>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83793 8.4825L0.70417 14.7173L2.10679 16.143L8.24054 9.90822L14.3751 16.1437L15.7777 14.718L9.64316 8.4825L15.7777 2.24699L14.3751 0.821272L8.24054 7.05679L2.10679 0.822038L0.704171 2.24775L6.83793 8.4825Z" fill="#252525"/>
                    </svg>
                </div>
                <div class="dashboard-documentation__sidebar__getting-started">
                    <div class="dashboard-documentation__sidebar__title">
                        Getting Started
                    </div>
                    <ul class="dashboard-documentation__sidebar__link-list">
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Installation
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Reporting
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Subscription
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Topic Here
                        </li>
                    </ul>
                </div>
                <div class="dashboard-documentation__sidebar__others">
                    <div class="dashboard-documentation__sidebar__title">
                    Others
                    </div>
                    <ul class="dashboard-documentation__sidebar__link-list">
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Installation
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Reporting
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Subscription
                        </li>
                        <li class="dashboard-documentation__sidebar__link-list__link">
                            Topic Here
                        </li>
                    </ul>
                </div>
            </div>
}
            
                
            </div>
            
            
        </div>
        <div
        onClick={() => {
            props.changeDashMenuStatus(false);
        }}
        className={`sidebar-backdrop ${props.dashMenuOn ? "" : "hide"}`}
    ></div>
    </>
    );
}

Sidebar.propTypes = {
    user: PropTypes.object,
    role: PropTypes.string,
    dashMenuOn: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        user: state.Auth.user,
        role: state.Auth.role,
        dashMenuOn: state.dashLayout.dashMenuOn,
        activeSideMenu: state.dashLayout.activeSideMenu
    };
};

export default connect(
    mapStateToProps,
    { changeDashMenuStatus, setActiveSideMenu }
)(withRouter(Sidebar));
