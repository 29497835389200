import React, { useState, useEffect } from "react";
import { withRouter,useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import classNames from "classnames";
import { debounce } from "../../utils/helpers/debounce";
import MainNav from "./MainNav";
import MobileNav from "./MobileNav";
import PaymentsMenu from "./PaymentsMenu";
import Footer from "../../components/misc/Footer";
import Chatbox from "../../components/misc/Chatbox";
import Ham from "../../components/reusable/others/Ham";
import Logo from "../../components/reusable/others/Logo";
import ThemeModal from "../../components/reusable/modals/ThemeModal";
import SimpleModal from "../../components/reusable/modals/SimpleModal";
import { changeSimpleModalStatus, changeThemeModalStatus, changeMobileMenuStatus } from "../../redux/actions";
import {useSelector} from 'react-redux'

function Main(props) {
    const path = props.history.location.pathname;
    const responsiveStatus = props.responsiveIndicators;
    const isSmallScreen = responsiveStatus.isSmallScreen || responsiveStatus.isXsSmallScreen;

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const AuthState=  useSelector(state => state.Auth);
    const history = useHistory();

    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;
        setVisible(
            (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 100) || currentScrollPos < 10
        );
        setPrevScrollPos(currentScrollPos);
    }, 50);

    useEffect(() => {
        if (!isSmallScreen) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (!isSmallScreen) {
                window.removeEventListener("scroll", handleScroll);
            }
        };
    }, [prevScrollPos, visible, handleScroll, isSmallScreen]);

    const navbarStyles = {
        position: "fixed",
        height: "70px",
        width: "100%",
        transition: "top 0.6s"
    };

    const goBack = ()=>{
        if(AuthState.isAuthenticated){
            history.push('/user/dashboard')
        }
        else{
            history.push('/pricing')
        }
    }

    return (
        <div className={`public-layout ${props.isAuthenticated && "display-none"}`}>
            {!props.isAuthenticated && (
                <>
                    <Chatbox path={props.history.location.pathname} />
                    <div className={`public-top-nav`} style={{ ...navbarStyles, top: visible ? "0" : "-70px" }}>
                        <div className="nav-left">
                            <Logo to="/" />
                            {path === "/payment" && 
                            <div style={{color:"#909090",marginLeft:"1rem",fontWeight:"500"}}>
                            Checkout
                            </div>
                            }
                        </div>
                        {path === "/payment" ? (
                            <>
                            <div style={{fontWeight:"500",cursor:"pointer",fontSize:"1rem",color:"#909090"}} onClick={goBack}>
                                <span className="close-payment-desktop">
                                    CLOSE
                                </span>
                                <span>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.7861 5.11176L12.8731 11.0248L6.96006 5.11176C6.81785 4.98161 6.6309 4.91136 6.43817 4.91563C6.24545 4.9199 6.0618 4.99837 5.92549 5.13468C5.78917 5.27099 5.71071 5.45464 5.70644 5.64737C5.70217 5.84009 5.77242 6.02704 5.90256 6.16926L11.8126 12.0823L5.90106 17.9938C5.82884 18.0626 5.77111 18.1452 5.73126 18.2366C5.6914 18.3281 5.67024 18.4266 5.669 18.5263C5.66777 18.6261 5.68649 18.7251 5.72406 18.8175C5.76164 18.9099 5.81731 18.9939 5.8878 19.0645C5.9583 19.1351 6.04219 19.1909 6.13456 19.2286C6.22692 19.2663 6.3259 19.2852 6.42565 19.2841C6.52541 19.283 6.62395 19.2619 6.71546 19.2222C6.80698 19.1825 6.88963 19.1249 6.95856 19.0528L12.8731 13.1413L18.7861 19.0543C18.9283 19.1844 19.1152 19.2546 19.308 19.2504C19.5007 19.2461 19.6843 19.1676 19.8207 19.0313C19.957 18.895 20.0354 18.7114 20.0397 18.5186C20.044 18.3259 19.9737 18.139 19.8436 17.9968L13.9306 12.0838L19.8436 6.16926C19.9158 6.10043 19.9735 6.01786 20.0134 5.9264C20.0532 5.83494 20.0744 5.73643 20.0756 5.63668C20.0769 5.53692 20.0582 5.43792 20.0206 5.3455C19.983 5.25308 19.9273 5.16911 19.8568 5.09851C19.7863 5.02792 19.7024 4.97213 19.6101 4.93442C19.5177 4.89672 19.4187 4.87786 19.319 4.87895C19.2192 4.88005 19.1207 4.90107 19.0292 4.94079C18.9377 4.98052 18.855 5.03813 18.7861 5.11026V5.11176Z" fill="#909090"/>
                                    </svg>

                                </span>
                            </div>
                            
                            </>
                        ) : (
                            <MainNav props={props} account={["/login", "/register"].includes(path)} />
                        )}
                    </div>
                    {path === "/payment" &&
                        (props.responsiveIndicators.isSmallScreen || props.responsiveIndicators.isXsSmallScreen) && (
                            <PaymentsMenu small />
                        )}
                </>
            )}

            <div className="public-wrapper">{props.children}</div>

            {!props.isAuthenticated && (
                <>
                    <Footer path={props.history.location.pathname} />

                    <MobileNav isActive={props.mobileMenuStatus} onClose={() => props.changeMobileMenuStatus(false)} />
                    <SimpleModal
                        isActive={props.simpleModalActive}
                        closeModal={() => props.changeSimpleModalStatus(false)}
                        title="Select Maintenance Plan"
                        subtitle="No Contracts, No Surprise Fee"
                    >
                        <span>--Modal Body--//</span>
                    </SimpleModal>
                    <ThemeModal
                        isActive={props.themeModalActive}
                        closeModal={() => props.changeThemeModalStatus(false)}
                        title="Select Maintenance Plan"
                        subtitle="No Contracts, No Surprise Fee"
                    >
                        <span>---Theme Modal Body---//</span>
                    </ThemeModal>
                </>
            )}
        </div>
    );
}

Main.propTypes = {
    simpleModalActive: PropTypes.bool.isRequired,
    themeModalActive: PropTypes.bool.isRequired,
    mobileMenuStatus: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        simpleModalActive: state.layout.simpleModalActive,
        themeModalActive: state.layout.themeModalActive,
        mobileMenuStatus: state.layout.mobileMenuStatus,
        responsiveIndicators: state.layout.responsiveIndicators
    };
};

export default connect(mapStateToProps, { changeSimpleModalStatus, changeThemeModalStatus, changeMobileMenuStatus })(
    withRouter(Main)
);
