export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";

export const LOG_IN_PENDING = "LOG_IN_PENDING";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILURE = "LOG_IN_FAILURE";

export const LOG_OUT_PENDING = "LOG_OUT_PENDING";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE";

export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const VERIFY_OTP_PENDING = "VERIFY_OTP_PENDING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
