import React from "react";
import { withRouter } from "react-router-dom";
import isEmpty from "../../../utils/isEmpty";

function MenuItem({ item, key, onItemClick, activeMenu, ...props }) {
    const _onMenuClick = (item, activeItem) => {
        if (item === activeItem) {
            onItemClick("");
            return;
        }
        onItemClick(item);
    };

    return (
        <>
            <li
                className={`${item.id === activeMenu ? "active" : ""}`}
                onClick={() => {
                    _onMenuClick(item.id, activeMenu);
                    if (item.type === "parent") {
                        props.history.push(item.url);
                    }
                }}
                key={key}
            >
                {item.icon.type === "react-icons" && <item.icon.value />}
                {item.icon.type === "feather" && <i className={`${item.icon.value}`} />}
                <b>{item.title}</b>
                <div className="menu-item-end">
                    {item.children && !isEmpty(item.children) ? (
                        <i
                            className={`menu-dropdown-${item.id} feather icon-chevron-up ${
                                item.id === activeMenu ? "side-dropdown-active" : ""
                            }`}
                        />
                    ) : null}
                    {!item.children && item.title === "Tickets" && (
                        <div className="menu-item-end-badge">
                            <b>1</b>
                        </div>
                    )}
                </div>
            </li>
        </>
    );
}

export default withRouter(MenuItem);
