import Backdrop from 'components/reusable/Backdrop/Backdrop'
import React from 'react'
import IndividualNotification from './IndividualNotification'
function Notifications({showNotifications,setShowNotifications}) {
    

    const tempData =[{
        title:"Ticket : Reply by Bizmandala",
        time:'2 day, 4:05 PM',
        type:'ticket',
        status:'opened'
    },
    {
        title:"Payment sucessfull for this month.",
        time:'3 day, 2:05 PM',
        type:'payment',
        status:'opened'
    },
    {
        title:"You just loggedin to this account",
        time:'2 day, 4:05 PM',
        type:'login',
        status:'not-opened'
    }] 
    
    return (
        <>
         <Backdrop isActive={showNotifications} close={()=>setShowNotifications(false)} />
        
        <div className={`notifications notifications--${showNotifications?'open':'close'}`}>
            <div className="notifications__header">

                <div className="notifications__header__title">

                    Notifications

                </div>
                <div className="notifications__header__close-btn" onClick={()=>setShowNotifications(prev=>!prev)}>

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06051 8.33897L0.926758 14.5737L2.32938 15.9994L8.46313 9.76468L14.5976 16.0002L16.0003 14.5745L9.86575 8.33897L16.0003 2.10345L14.5976 0.677734L8.46313 6.91325L2.32938 0.6785L0.926758 2.10421L7.06051 8.33897Z" fill="#252525"/>
                    </svg>

                </div>
            </div>
            <div className="notifications__section">

                    <div className="notifications__section__header">

                        <div className="notifications__section__header__date">
                            Today's
                        </div>
                        <div className="notifications__section__header__mark">
                            Mark All
                        </div>

                    </div>

                    <div className="notifications__section__body">

                        {
                            tempData && tempData.map(notf=>{
                               return <IndividualNotification data={notf}/>
                            })
                        }

                    </div>

            </div>

           
        </div>
        </>
    )
}

export default Notifications
