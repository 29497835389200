// all api calls for blog page

import { pubRequest } from "../wrapper";

export const getFaqs = async (data, query = "limit=5", signal) => {
    try {
        return await pubRequest("api/site/faqs/" + (query ? "?" + query : ""), "GET", data, signal);
    } catch (err) {
        throw err;
    }
};
