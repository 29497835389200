import React,{useState,useEffect} from "react";
import { connect, useDispatch } from "react-redux";

import { changeDashMenuStatus } from "../../../redux/actions";
import { logOut } from "redux/actions/authActions";
import { Dropdown, Badge, Image } from "react-bootstrap";
import IconButton from "../../../components/reusable/buttons/IconButton";
import { CgPushChevronLeft, CgPushChevronRight } from "react-icons/cg";
import { BsBell } from "react-icons/bs";
import {useHistory,useLocation} from 'react-router-dom';
import Notifications from '../../../components/dashboard/Notifications'
 
function Topbar(props) {
    
    const dispatch = useDispatch();
    const history = useHistory();
    const location= useLocation();
    const [showNotifications,setShowNotifications]= useState(false);

    let topbarClasses = ["dashboard-topbar", "top-bar-open"];
    if (!props.dashMenuOn) {
        topbarClasses = ["dashboard-topbar", "top-bar-close"];
    }

    let username = props.user ? `${props.user.first_name} ${props.user.last_name}` : "Unknown";
    let role = props.role ? props.role : "unknown";

    const ProfileDropdown = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="#profile"
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ height: "100%" }}
        >
            <div>
                {/* avatar */}
                <Image
                    style={{ width: "30px", height: "30px" }}
                    src="https://cdn-icons-png.flaticon.com/512/147/147144.png"
                    roundedCircle
                />
                {/* user */}
                <div className="user-top">
                    <b>{username.substring(0, 10)}</b>
                    <span>{role}</span>
                </div>
                {/* icon */}
                <i className="icon feather icon-chevron-down icon-desktop" />
            </div>
        </a>
    ));
    const NotificationToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="#notification"
            style={{
                height: "100%",
                cursor: "pointer"
            }}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                setShowNotifications(prev=>!prev)
            }}
        >
            <IconButton>
                <BsBell style={{ fontSize: "20px" }} />
                <Badge
                    style={{
                        position: "absolute",
                        right: "-6px",
                        top: "-3px",
                        borderRadius: "0px !important",
                        padding: "2px 4px 2px 4px"
                    }}
                    bg="danger"
                    variant="primary"
                >
                    {1}
                </Badge>
            </IconButton>
        </a>
    ));

    const userLogout = async()=>{
       await dispatch(logOut());
        history.push('/')

    }

    useEffect(() => {
        setShowNotifications(false)
    }, [location.pathname])

    
    return (
        <>
        <div className={topbarClasses.join(" ")}>
            <div className="dashboard-topbar-container">
                <div className="content-left">
                    {
                        props.isUserDocumentaionPage?
                        <IconButton onClick={() => props.changeDashMenuStatus(!props.dashMenuOn)}>
                            <CgPushChevronLeft style={{ fontSize: "19px", marginLeft: "-5px" }} />
                        </IconButton>

                        :
                    
                    props.dashMenuOn ? (
                        <IconButton onClick={() => props.changeDashMenuStatus(!props.dashMenuOn)}>
                            <CgPushChevronLeft style={{ fontSize: "19px", marginLeft: "-5px" }} />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => props.changeDashMenuStatus(!props.dashMenuOn)}>
                            <CgPushChevronRight style={{ fontSize: "19px", marginLeft: "-5px" }} />
                        </IconButton>
                    )}
                    <div>
                        <h6>Dashboard</h6>
                    </div>
                </div>
                <div className="content-right">
                    {/* notification dropdown */}
                    <div className="nav-right-notification">
                        <NotificationToggle/>
                    </div>
                    {/* profile dropdown */}
                    <Dropdown className="nav-right-profile">
                        <Dropdown.Toggle id="dropdown-basic" as={ProfileDropdown}></Dropdown.Toggle>
                        <Dropdown.Menu align="end" className="profile-dropdown">
                            <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                <span>Profile Options</span>
                                <span onClick={() =>userLogout() }>Log out</span>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        
        
        </div>
        
        <Notifications setShowNotifications={setShowNotifications} showNotifications={showNotifications}/>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.Auth.user,
        role: state.Auth.role,
        dashMenuOn: state.dashLayout.dashMenuOn
    };
};

export default connect(
    mapStateToProps,
    { changeDashMenuStatus }
)(Topbar);
