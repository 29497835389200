import * as Types from "../actions/types";
import * as api from "../../api/public/auth";
import * as privateApi from "../../api/private/auth";
import JWTdecode from "jwt-decode";

export const logIn = formData => async dispatch => {
    dispatch({ type: Types.LOG_IN_PENDING, payload: formData });
    try {
        const res = await api.signIn(formData);
        if (res.response.status === 200) {
            console.log("JWTdecode", JWTdecode(res.json.tokens.access));
            // localStorage.setItem("tokens", JSON.stringify(res.json.tokens));
            dispatch({ type: Types.LOG_IN_SUCCESS, payload: res.json });
        } else {
            dispatch({ type: Types.LOG_IN_FAILURE, payload: res.json.error });
        }
    } catch (error) {
        dispatch({ type: Types.LOG_IN_FAILURE, payload: error });
    }
};

export const logOut = () => async dispatch => {
    await privateApi.logOut();
    dispatch({ type: Types.LOG_OUT_SUCCESS });
};

export const register = formdata => async dispatch => {
    dispatch({ type: Types.REGISTER_PENDING });
    try {
        const res = await api.register(formdata);
        console.log({ res });
        if (res.response.status === 201) {
            console.log("success");
            dispatch({ type: Types.REGISTER_SUCCESS });

            dispatch({ type: Types.VERIFY_OTP_PENDING });
        } else {
            dispatch({ type: Types.REGISTER_FAILURE, payload: res.json });
        }
    } catch (error) {
        dispatch({ type: Types.REGISTER_FAILURE, payload: error });
    }
};

export const verifyOTP = email => async dispatch => {
    dispatch({ type: Types.VERIFY_OTP_PENDING });
    try {
        const res = await api.verifyOTP(email);
        if (res.response.status === 200) {
            dispatch({ type: Types.VERIFY_OTP_SUCCESS });
        } else {
            dispatch({ type: Types.VERIFY_OTP_FAILURE, payload: res.json });
        }
    } catch (error) {
        dispatch({ type: Types.VERIFY_OTP_FAILURE, payload: error });
    }
};

export const registrationConfirm = status => {
    return dispatch => {
        dispatch({ type: Types.CHANGE_MOBILE_MENU_STATUS, payload: status });
    };
};
