import * as Types from "../actions/types";

const INITIAL_STATE = {
    selectedPlanDetails: {},
    selectedProductDetails: {},
    selectedPeriodType: "monthly", //monthly,annually
    plansData: [],
    subscriptionDetails: {},
    invoiceDetails: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_INVOICE_DETAILS:
            return { ...state, invoiceDetails: action.payload };
        case Types.SET_SUBSCRIPTION_DETAILS:
            return { ...state, subscriptionDetails: action.payload };
        case Types.SET_PLANS_DATA:
            return { ...state, plansData: action.payload };
        case Types.SET_PERIOD_TYPE:
            return { ...state, selectedPeriodType: action.payload };
        case Types.SET_PLAN_DETAILS:
            return { ...state, selectedPlanDetails: action.payload };
        case Types.SET_PRODUCT_DETAILS:
            return { ...state, selectedProductDetails: action.payload };
        default:
            return { ...state };
    }
};
