const blobToBase64 = (blob) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);

	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};
export { blobToBase64 };
