import { privateRequest } from "../wrapper";

const logOut = async () => {
    try {
        return await privateRequest("api/users/logout", "GET");
    } catch (err) {
        throw err;
    }
};

export { logOut };
