import React from "react";

function IconMenuSubItem({ item, subIndex, ...props }) {
    return (
        <li className="icon-menu-sub-item" key={subIndex}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </li>
    );
}

export default IconMenuSubItem;
