import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { nextStep } from "../../redux/payment";

const PaymentsMenu = ({ small }) => {
    const step = useSelector((state) => state.paymentStore.step);
    const dispatch = useDispatch();

    return (
        <>
        </>
        // <div className={`payment__menu ${small && "payment__menu--large"}`}>
        //     {/* {["Select Product", "Select Plan", "Payment"].map((data, i) => (
        //         <label key={i} className={`payment__menu__li ${step === i && "payment__menu__li--active"}`}>
        //             <input type="radio" name="payment-nav" value={i} onChange={() => dispatch(nextStep(i))} />
        //             {data}
        //         </label>
        //     ))} */}
        //     <a href="/" className="cancel">
        //         Cancel
        //     </a>
        // </div>
    );
};

export default PaymentsMenu;
