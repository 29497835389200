// all api calls for blog page
import { pubRequest } from "../wrapper";

const signIn = async data => {
    try {
        return await pubRequest("api/users/login", "POST", data);
    } catch (err) {
        throw err;
    }
};

const register = async data => {
    try {
        return await pubRequest("api/users/register", "POST", data);
    } catch (err) {
        throw err;
    }
};

const sendOTP = async email => {
    try {
        return await pubRequest("api/users/verify/email", "POST", email);
    } catch (err) {
        throw err;
    }
};
const verifyOTP = async email => {
    try {
        return await pubRequest("api/users/verify/email/otp", "POST", email);
    } catch (err) {
        throw err;
    }
};

export { signIn, register, verifyOTP, sendOTP };
