import React from "react";
import Backdrop from "../../components/reusable/Backdrop/Backdrop";
import { X } from "react-feather";
import { withRouter } from "react-router-dom";

const navOptions = [
	{
		name: "Home",
		path: "/"
	},
	{
		name: "Feature",
		path: "/feature"
	},
	{
		name: "Pricing",
		path: "/pricing"
	},
	{
		name: "Documentation",
		path: "/documentation"
	},
	{
		name: "Account",
		path: "/login"
	}
	
];

function MobileNav(props) {
	let classWrapper = ["mobile-nav-container", "close"];
	if (props.isActive) {
		classWrapper = ["mobile-nav-container", "open"];
	}
	const navigateTo = (path) => {
		props.onClose();
		props.history.push(path);
	};

	return (
		<>
			<div className={classWrapper.join(" ")}>
				<div className="mobile-nav-content">
					<div className="mobile-nav-content__header">

						<div className="mobile-nav-content__header__heading">
							MENU
						</div>

						<div className="close-button">
							<X onClick={props.onClose} />
						</div>

					</div>
					
					{navOptions.map((data, index) => {
						return (
							<div className="mobile-nav-content__nav-link" key={index} onClick={() => navigateTo(data.path)}>
								{data.name}
							</div>
						);
					})}
					<div className="mobile-nav-content__nav-link mobile-nav-content__nav-link--trial"  onClick={() => navigateTo('/pricing')}>
								Start Free trial
					</div>
				</div>
			</div>
			<Backdrop isActive={props.isActive} close={props.onClose} />
		</>
	);
}

export default withRouter(MobileNav);
