// home, login, signup
import React from "react";

const Guides = React.lazy(() => import("pages/Public/Docs/Guides"));
const Home = React.lazy(() => import("../pages/Public/Home/Home"));
const Pricing = React.lazy(() => import("../pages/Public/Pricing"));
const Documentation = React.lazy(() => import("../pages/Public/Documentation/Documentation"));
const Feature = React.lazy(() => import("../pages/Public/Feature/Feature"));
const Login = React.lazy(() => import("../pages/Public/Account/Login"));
const Register = React.lazy(() => import("../pages/Public/Account/Register"));
const Blogs = React.lazy(() => import("../pages/Public/Blogs"));
const SingleBlog = React.lazy(() => import("../pages/Public/Blogs/OneBlog"));
const Payment = React.lazy(() => import("../pages/Public/Payment"));

const pubRoutes = [
    {
        path: "/",
        exact: true,
        name: "Home",
        component: Home
    },
    {
        path: "/pricing",
        exact: true,
        name: "Pricing",
        component: Pricing
    },
    {
        path: "/documentation",
        exact: true,
        name: "Documentation",
        component: Documentation
    },
    {
        path: "/feature",
        exact: true,
        name: "Feature",
        component: Feature
    },
    {
        path: "/login",
        exact: true,
        name: "Login",
        component: Login
    },
    {
        path: "/register",
        exact: true,
        name: "Register",
        component: Register
    },
    {
        path: "/payment",
        exact: true,
        name: "Payment",
        component: Payment
    },
    {
        path: "/blogs",
        exact: true,
        name: "Blogs",
        component: Blogs
    },
    {
        path: "/blog/:id",
        exact: true,
        name: "Blogs Title",
        component: SingleBlog
    },
    {
        path: "/guides",
        exact: true,
        name: "Style Guides",
        component: Guides
    }
];

export default pubRoutes;
