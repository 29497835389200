import React from "react";
import Biz from "../../../assets/images/biz.png";

function Logo(props) {
    return (
        <>
            <img onClick={props.onClick} src={Biz} alt="Logo" />
            <b onClick={props.onClick}>Mandala</b>
        </>
    );
}

export default Logo;
