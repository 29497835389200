import React from "react";
import Backdrop from "../Backdrop/Backdrop";

function SimpleModal(props) {
    let classWrapper = ["simple-modal", "close"];
    if (props.isActive) {
        classWrapper = ["simple-modal", "open"];

        if (props.isDashboard) {
            classWrapper = ["simple-modal", "simple-modal--dashboard", "open"];
        }
    } else {
        classWrapper = ["simple-modal", "close"];

        if (props.isDashboard) {
            classWrapper = ["simple-modal", "simple-modal--dashboard", "close"];
        }
    }
    return (
        <>
            <Backdrop isActive={props.isActive} close={props.closeModal} />
            <div className={`${classWrapper.join(" ")} modal-${props.size || "md"}`}>
                <div className="modal-content">
                    {!props.isDashboard && (
                        <div className="modal-title-container">
                            <div className="title-left">
                                {props.title && <span className="modal-title">{props.title}</span>}
                                <br />
                                {props.subtitle && <span className="modal-sub-title">{props.subtitle}</span>}
                            </div>
                            <div className="title-right">{/*Title Right Content*/}</div>
                        </div>
                    )}

                    <div className="modal-body">{props.children}</div>
                </div>

                {props.type === "payment-plan" && (
                    <div className="payment__plan__cancel" onClick={props.closeModal}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.2686 10.5565L19.2387 3.60267C19.5439 3.29744 19.7154 2.88346 19.7154 2.4518C19.7154 2.02014 19.5439 1.60615 19.2387 1.30093C18.9335 0.995695 18.5195 0.824219 18.0878 0.824219C17.6562 0.824219 17.2422 0.995695 16.937 1.30093L9.98311 8.27099L3.02926 1.30093C2.72403 0.995695 2.31005 0.824219 1.87839 0.824219C1.44673 0.824219 1.03275 0.995695 0.727519 1.30093C0.42229 1.60615 0.250813 2.02014 0.250813 2.4518C0.250813 2.88346 0.42229 3.29744 0.727519 3.60267L7.69758 10.5565L0.727519 17.5104C0.575591 17.6611 0.455002 17.8403 0.372709 18.0379C0.290416 18.2354 0.248047 18.4473 0.248047 18.6612C0.248047 18.8752 0.290416 19.0871 0.372709 19.2846C0.455002 19.4821 0.575591 19.6614 0.727519 19.8121C0.878207 19.964 1.05749 20.0846 1.25501 20.1669C1.45254 20.2492 1.66441 20.2916 1.87839 20.2916C2.09237 20.2916 2.30424 20.2492 2.50177 20.1669C2.69929 20.0846 2.87857 19.964 3.02926 19.8121L9.98311 12.842L16.937 19.8121C17.0877 19.964 17.2669 20.0846 17.4645 20.1669C17.662 20.2492 17.8738 20.2916 18.0878 20.2916C18.3018 20.2916 18.5137 20.2492 18.7112 20.1669C18.9087 20.0846 19.088 19.964 19.2387 19.8121C19.3906 19.6614 19.5112 19.4821 19.5935 19.2846C19.6758 19.0871 19.7182 18.8752 19.7182 18.6612C19.7182 18.4473 19.6758 18.2354 19.5935 18.0379C19.5112 17.8403 19.3906 17.6611 19.2387 17.5104L12.2686 10.5565Z"
                                fill="black"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </>
    );
}

export default SimpleModal;
