import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import App from "./layout/App";
import ErrorBoundary from "./components/misc/ErrorBoundary";

const History = createBrowserHistory();

const app = (
    <Provider store={store}>
        <Router history={History}>
            <PersistGate loading={null} persistor={persistor}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </PersistGate>
        </Router>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

reportWebVitals();
