import React from "react";

function index() {
	return (
		<div className="page-not-found">
			<p>--PAGE NOT FOUND--</p>
		</div>
	);
}

export default index;
