import React from "react";
import { withRouter } from "react-router-dom";

function MenuSubItem({ item, activeMenu, subIndex, ...props }) {
    const _onSubMenuClick = url => {
        props.history.push(url);
    };
    let currentPath = props.location.pathname.split("/");

    return (
        <li
            onClick={() => {
                _onSubMenuClick(item.url);
            }}
            className={`menu-sub-item ${currentPath[2] === item.id ? "active" : ""} `}
            key={subIndex}
        >
            <span>
                {item.title} ({subIndex})
            </span>
        </li>
    );
}

export default withRouter(MenuSubItem);
