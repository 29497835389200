import * as Types from "../actions/types";
import { REHYDRATE } from "redux-persist/lib/constants";

const INITIAL_STATE = {
    isAuthenticated: false,
    role: "", //staff,user
    loading: false,
    error: "",
    user: {},
    success: false,
    access: "",
    refresh: ""
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE: {
            let authState = action.payload && action.payload.Auth ? action.payload.Auth : {};
            if (authState) return { ...state, ...authState };
            return state;
        }
        case Types.SET_IS_AUTHENTICATED:
            return { ...state, isAuthenticated: action.payload };

        case Types.LOG_IN_PENDING:
            return { ...state, isAuthenticated: false, loading: true, error: "" };
        case Types.LOG_IN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                access: action.payload.tokens.access,
                refresh: action.payload.tokens.refresh,
                user: action.payload.user_details.user,
                role: action.payload.user_details.user.is_staff ? "staff" : "user"
            };
        case Types.LOG_IN_FAILURE:
            return { ...state, isAuthenticated: false, loading: false, error: action.payload };

        case Types.REGISTER_PENDING:
            return { ...state, isAuthenticated: false, loading: true, error: "" };
        case Types.REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                success: true
            };
        case Types.REGISTER_FAILURE:
            return { ...state, isAuthenticated: false, loading: false, error: action.payload };

        case Types.REFRESH_TOKEN:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                access: action.payload.access,
                refresh: action.payload.refresh
            };

        case Types.LOG_OUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                access: "",
                refresh: "",
                user: {},
                role: ""
            };
        default:
            return { ...state };
    }
};

export default authReducer;
