import * as Types from "../actions/types";

export const setResponsiveIndicators = (indicators) => {
	return (dispatch) => {
		dispatch({ type: Types.SET_RESPONSIVE_INDICATORS, payload: indicators });
	};
};

export const changeSimpleModalStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: Types.CHANGE_SIMPLE_MODAL_STATUS, payload: status });
	};
};

export const changeThemeModalStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: Types.CHANGE_THEME_MODAL_STATUS, payload: status });
	};
};

export const changeMobileMenuStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: Types.CHANGE_MOBILE_MENU_STATUS, payload: status });
	};
};
