import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step: 0,
    type: "annual",
    product: -1,
    plan: -1,
    platform: "esewa",
    changeProduct: false,
    planDetails: {},
    productDetails: {}
};

export const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        nextStep: (state, action) => {
            state.step = action.payload;
        },
        setSubType: (state, action) => {
            state.type = action.payload;
        },
        setSelectProduct: (state, action) => {
            state.product = action.payload;
        },
        setPlan: (state, action) => {
            state.plan = action.payload;
        },
        setPayment: (state, action) => {
            state.platform = action.payload;
        },
        setChangeProduct: (state, action) => {
            state.changeProduct = action.payload;
        }
    }
});

export const { nextStep, setChangeProduct, setSubType, setSelectProduct, setPlan, setPayment } = paymentSlice.actions;

export const stepSelector = state => state.step;

export default paymentSlice.reducer;

// export const userLogin = (logIn = initState, action) => {
//   switch (action.type) {
//     case LOG_IN_TOKEN:
//       return {
//         error: false,
//         loading: false,
//         success: true,
//         isAuthenticated: true,
//         message: "Log in success via token",
//         user: action.payload,
//       }

//     case LOG_IN_SUCCESS:
//       return {
//         error: false,
//         success: true,
//         loading: false,
//         isAuthenticated: true,
//         message: "User logged in",
//         user: action.payload.user,
//       }

//     case LOG_OUT:
//       return {
//         error: false,
//         loading: false,
//         success: true,
//         message: "User logged out",
//         isAuthenticated: false,
//         user: {},
//       }

//     case REGISTER_SUCCESS:
//       return {
//         error: false,
//         loading: false,
//         success: true,
//         isAuthenticated: true,
//         message: "New user resistered",
//         user: action.payload.user,
//       }

//     case LOGIN_ERROR:
//       const message = action.payload.email || action.payload.username
//       return {
//         error: true,
//         loading: false,
//         success: false,
//         isAuthenticated: false,
//         message: `Log In failed ${message}`,
//         user: {},
//       }

//     case FOLLOW_USER:
//       return {
//         ...logIn,
//       }

//     case REGISTER_ERROR:
//       const messag =
//         action.payload.email || action.payload.username || action.payload.password || action.payload.re_password
//       return {
//         error: true,
//         loading: false,
//         success: false,
//         isAuthenticated: false,
//         message: `Register failed ${messag}`,
//         user: {},
//       }

//     default:
//       return logIn
//   }
// }
