import React from "react";
import { NavLink } from "react-router-dom";
import Ham from "../../components/reusable/others/Ham";
import NavItems from "./NavItems";

const MainNav = ({ props, account }) => {
    return (
        <>
            <div className="nav-mid">{!account && <NavItems />}</div>
            <div className="nav-right">
                <Ham onClick={() => props.changeMobileMenuStatus(true)} />
                <div className="nav-items">
                    {account && <NavItems account />}
                    <NavLink to="/login">
                        <span>Account</span>
                    </NavLink>
                    {!account && <button className="btn-primary">Start Free Trial</button>}
                </div>
            </div>
        </>
    );
};

export default MainNav;
