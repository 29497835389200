import * as Types from "../actions/types";

const INITIAL_STATE = {
    standardBreakpoints: {
        xsSmallScreen: 575.98,
        smallScreen: 767.98,
        mediumScreen: 991.98,
        largeScreen: 1199.98
    },
    responsiveIndicators: {
        // for mobile screens
        isXsSmallScreen: false,
        isSmallScreen: false,
        // for tab screens
        isMediumScreen: false,
        // for desktop screen and larger
        isLargeScreen: false,
        isXlLargeScreen: false
    },
    simpleModalActive: false,
    themeModalActive: false,
    mobileMenuStatus: false
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
const layoutReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.CHANGE_MOBILE_MENU_STATUS:
            return {
                ...state,
                mobileMenuStatus: action.payload,
                simpleModalActive: false,
                themeModalActive: false
            };
        case Types.CHANGE_THEME_MODAL_STATUS:
            return {
                ...state,
                simpleModalActive: false,
                themeModalActive: action.payload
            };
        case Types.CHANGE_SIMPLE_MODAL_STATUS:
            return { ...state, simpleModalActive: action.payload };
        case Types.SET_RESPONSIVE_INDICATORS:
            return { ...state, responsiveIndicators: action.payload };
        default:
            return { ...state };
    }
};
export default layoutReducer;
