import * as Types from "./types";

export const selectSubPlan = data => {
    return dispatch => {
        dispatch({ type: Types.SET_PLAN_DETAILS, payload: data });
    };
};

export const selectSubProduct = data => {
    return dispatch => {
        dispatch({ type: Types.SET_PRODUCT_DETAILS, payload: data });
    };
};

export const setPeriodType = data => {
    return dispatch => {
        dispatch({ type: Types.SET_PERIOD_TYPE, payload: data });
    };
};

export const setPlansData = data => {
    return dispatch => {
        dispatch({ type: Types.SET_PLANS_DATA, payload: data });
    };
};

export const setSubscriptionDetails = data => {
    return dispatch => {
        dispatch({ type: Types.SET_SUBSCRIPTION_DETAILS, payload: data });
    };
};

export const setInvoiceDetails = data => {
    return dispatch => {
        dispatch({ type: Types.SET_INVOICE_DETAILS, payload: data });
    };
};
