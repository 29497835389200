import React from "react";
import { withRouter,useHistory } from "react-router-dom";
import{useDispatch} from 'react-redux';
import { logOut } from "redux/actions";
function MenuBottomItems({ item, indexx, onItemClick, activeMenu, ...props }) {
   
    const dispatch = useDispatch();
    const history = useHistory();

   
    const _onMenuClick = (item, activeItem) => {
        if (item === activeItem) {
            onItemClick("");
            return;
        }
        onItemClick(item);
    };

    const _logout = async() => {
        console.log("Logout?")
        await dispatch(logOut())
        history.push("/");
    };

    return (
        <>
            <li
                className={`${item.id === activeMenu ? "active" : ""}`}
                onClick={() => {
                    _onMenuClick(item.id, activeMenu);
                    if (item.type === "parent") {
                        props.history.push(item.url);
                    }
                    if (item.type === "button") {
                        _logout();
                    }
                }}
                key={indexx}
            >
                {item.icon.type === "react-icons" && <item.icon.value />}
                {item.icon.type === "feather" && <i className={`${item.icon.value}`} />}
                <b>{item.title}</b>
            </li>
        </>
    );
}

export default withRouter(MenuBottomItems);
