import React from "react";
import Backdrop from "../Backdrop/Backdrop";
import { X } from "react-feather";

function ThemeModal({ isActive, closeModal, title, subtitle, ...props }) {
    let classWrapper = ["theme-modal", "theme-close"];
    if (isActive) {
        classWrapper = ["theme-modal", "theme-open"];
    }

    return (
        <>
            <Backdrop isActive={isActive} close={closeModal} />
            <div className={`${classWrapper.join(" ")}  modal-${props.size || "md"}`}>
                <div className="theme-modal__modal-close">
                    <div className="theme-modal__modal-close__close-btn" onClick={closeModal}>
                        <X />
                    </div>
                </div>
                <div className="theme-modal__modal-content">
                    <div className="theme-modal__modal-content__modal-title-container">
                        <div className="theme-modal__modal-content__modal-title-container__title-left">
                            {title && (
                                <span className="theme-modal__modal-content__modal-title-container__title-left__modal-title">
                                    {title}
                                </span>
                            )}
                            <br />
                            {subtitle && (
                                <span className="theme-modal__modal-content__modal-title-container__title-left__modal-sub-title">
                                    {subtitle}
                                </span>
                            )}
                        </div>
                        <div className="theme-modal__modal-content__modal-title-container__title-right">
                            {/*Title Right Content*/}
                        </div>
                    </div>
                    <br />
                    <div className="theme-modal__modal-content__modal-body" style={{ paddingLeft: "0px" }}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThemeModal;
