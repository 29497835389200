import * as Types from "../actions/types";

const INITIAL_STATE = {
    dashMenuOn: true,
    activeSideMenu: "",
    isUserDocumentaionPage:false
};

const dashLayoutReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_DASHBOARD_MENU_ON:
            return { ...state, dashMenuOn: action.payload };
        case Types.SET_ACTIVE_SIDE_MENU:
            return { ...state, activeSideMenu: action.payload };
        case Types.IS_USER_DOCUMENTATION_PAGE:
            return{
                ...state,
                isUserDocumentaionPage:action.payload
            }
        default:
            return { ...state };
    }
};

export default dashLayoutReducer;
