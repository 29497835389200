import { IoTicketOutline } from "react-icons/io5";
import { BsGraphUp,BsGlobe } from "react-icons/bs";
import { MdSubscriptions } from "react-icons/md";
import { RiDashboardFill, RiContactsBook2Line } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { IoIosCloudOutline } from "react-icons/io";
import { ReactComponent as Subcription } from "../../assets/icons/human-resources/svg/subscription.svg";
import { ReactComponent as ManageResponse } from "../../assets/icons/human-resources/svg/manage-response.svg";
import { ReactComponent as Products } from "../../assets/icons/human-resources/svg/products.svg";
import { ReactComponent as BlogIcon } from "../../assets/icons/human-resources/svg/blogs.svg";
import { ReactComponent as Tickets } from "../../assets/icons/human-resources/svg/tickets.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/icons/human-resources/svg/website.svg";

// for staff
const menuItems = [
    {
        id: "dashboard-home",
        title: "Home",
        type: "parent",
        url: "/dashboard",
        icon: { type: "react-icons", value: RiDashboardFill }
    },
    {
        id: "subscriptions",
        title: "Subscriptions",
        type: "parent",
        url: "/dashboard/subscriptions",
        icon: { type: "feather", value: "feather icon-user" }
    },
    {
        id: "products",
        title: "Products",
        type: "collapse",
        icon: { type: "react-icons", value: Products },
        children: [
            {
                id: "products",
                title: "All",
                type: "item",
                url: "/dashboard/products"
            }
        ]
    },
    {
        id: "analytics",
        title: "Analytics",
        type: "parent",
        url: "/dashboard/analytics",
        icon: { type: "react-icons", value: BsGraphUp }
    },
    {
        id: "blogs",
        title: "Blogs",
        type: "collapse",
        icon: { type: "react-icons", value:BlogIcon },
        children: [
            {
                id: "blogs",
                title: "All",
                type: "item",
                url: "/dashboard/blogs"
            },
            {
                id: "scheduled",
                title: "Scheduled",
                type: "item",
                url: "/dashboard/scheduled"
            },
            {
                id: "published",
                title: "Published",
                type: "item",
                url: "/dashboard/published"
            }
        ]
    },
    {
        id: "tickets",
        title: "Tickets",
        type: "parent",
        url: "/dashboard/tickets",
        icon: { type: "react-icons", value: Tickets }
        
    },

    {
        id:"website",
        title:"Website",
        type: "collapse",
        icon: { type: "react-icons", value: WebsiteIcon },
        children: [
            {
                id: "highlighted-product",
                title: "Highlighted product",
                type: "item",
                url: "/dashboard/highlighted-products"
            },
            {
                id: "featured-client",
                title: "Featured client",
                type: "item",
                url: "/dashboard/featured-clients"
            },
            {
                id: "embeded-links",
                title: "Embeded links",
                type: "item",
                url: "/dashboard/embeded-links"
            },
            {
                id: "faqs",
                title: "Faqs",
                type: "item",
                url: "/dashboard/faqs"
            }
        ]
    }
    ,
    {
        id: "form-response",
        title: "Form Response",
        type: "parent",
        url: "/dashboard/form-response",
        icon: { type: "react-icons", value: ManageResponse }
          
    }
];

// for staff
const bottomMenuItems = [
    {
        id: "settings",
        title: "Settings",
        type: "parent",
        url: "/dashboard/settings",
        icon: { type: "feather", value: "feather icon-settings" }
    },
    {
        id: "logout",
        title: "Logout",
        type: "button",
        icon: { type: "react-icons", value: AiOutlineLogout }
    },


];

// for client/user
const userMenuItems = [
    // {
    //     id: "user-dashboard-home",
    //     title: "ERP",
    //     type: "parent",
    //     url: "/user/dashboard",
    //     icon: { type: "react-icons", value: RiDashboardFill }
    // },
    {
        id: "companies",
        title: "ERP",
        type: "parent",
        url: "/user/dashboard/erp",
        icon: { type: "react-icons", value: IoIosCloudOutline }
    },
    {
        id: "subscriptions",
        title: "Subscription",
        type: "parent",
        url: "/user/dashboard/subscriptions",
        icon: { type: "react-icons", value: Subcription }
    },
    {
        id: "tickets",
        title: "Tickets",
        type: "parent",
        url: "/user/dashboard/tickets",
        icon: { type: "react-icons", value: Tickets }
    },
    {
        id: "dashboard-documentation",
        title: "Documentation",
        type: "parent",
        url: "/user/dashboard/documentation",
        icon: { type: "react-icons", value: AiOutlineFile }
    },
    {
        id: "blogs",
        title: "Blogs",
        type: "collapse",
        icon: { type: "react-icons", value: BlogIcon },
        children: [
            {
                id: "blogs",
                title: "All",
                type: "item",
                url: "/user/dashboard/blogs"
            },
            {
                id: "scheduled",
                title: "Scheduled",
                type: "item",
                url: "/user/dashboard/scheduled"
            },
            {
                id: "published",
                title: "Published",
                type: "item",
                url: "/user/dashboard/published"
            }
        ]
    },
    
];

// for client/user
const userBottomMenuItems = [
    {
        id: "contact",
        title: "Contact",
        type: "parent",
        url: "/user/dashboard/contact",
        icon: { type: "react-icons", value: RiContactsBook2Line }
    },
    {
        id: "settings",
        title: "Settings",
        type: "parent",
        url: "/user/dashboard/settings",
        icon: { type: "feather", value: "feather icon-settings" }
    },
    {
        id: "logout",
        title: "Logout",
        type: "button",
        icon: { type: "react-icons", value: AiOutlineLogout }
    }
];

export { menuItems, bottomMenuItems, userMenuItems, userBottomMenuItems };
