import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFaqs } from "../api/public/faq";

export const getFAQ = createAsyncThunk("faq/get/", async () => {
    try {
        return await getFaqs();
    } catch (err) {
        return err;
    }
});

const initialState = {
    success: false,
    loading: false,
    faqList: [],
    error: ""
};

export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        reset: (state) => {
            state = { ...initialState };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFAQ.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFAQ.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
                state.faqList = payload?.json?.data;
            })
            .addCase(getFAQ.rejected, (state, { payload }) => {
                state.loading = false;
                state.success = false;
                state.error = payload;
            });
    }
});

export const { reset } = faqSlice.actions;
export const faqSelector = (state) => state.faqStore.faqList;

export default faqSlice.reducer;
